import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PlayCircleFilled, PauseCircleFilled } from '@material-ui/icons'
import { toJS } from 'mobx'
import { Accordion, AccordionSummary, AccordionDetails, Radio, TextField, Button, RadioGroup, FormControlLabel, FormControl, Checkbox } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Space, Icon, theme, Row, Text } from 'ui'
import useStore from 'store'
import groupCategories from '../Groups/groupCategories.json'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UserGroup, Description, Group } from 'utils/types'
import { AssociatedGroupsLabel, Role } from 'utils/models'
import { canDeleteGroup, userCanSeeAssociatedGroups, userIsDirectorOrSuperAdmin, userIsOrganizer } from 'utils/roles'
import { isTotzeret } from 'service/whiteLable'

const emergencySound = new Audio("/group_emergency.mp3");

const StyledTextField = styled(TextField)`
  background-color: ${theme.color.white};
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};

 width:100%;
`
const ConfirmButton = styled(Button) <{ bgColor: string, hoverColor?: string }>`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background:${p => p.bgColor};
    &:hover {
      background: ${p => p.hoverColor ? p.hoverColor : theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`
const Container = styled.div`
  overflow:auto;
  padding:10px;
`
const ConfirmButtonsContainer = styled.div`
  background-color: ${theme.color.white};
  display:flex;
  position:relative;
  bottom:0;
  right:0;
  width:100%;
`
const Overlay = styled(Row) <{ color: string }>`
  background-color: ${p => p.color};
  justify-content:space-between;
  flex:1;
`
const AutoCompleteContainer = styled(Autocomplete)`
  margin:10px 0 ;
`

const CustomAccordion = styled(Accordion)`
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  .MuiTypography-body1{
    margin-left: 15px;
    margin-right: 15px;
  }
  &:before {
      height: 0px;
    }
  .MuiIconButton-root {
      padding:0;
  }
  .MuiAccordionSummary-expandIcon{
    margin: auto;
  }
 
`
const AccordionContainer = styled.div`
  margin: 15px 0;
`

const IconComponent = styled.div`
  display: flex;
  align-items: center;
`


const IconBg = styled.div<{ color: string, selected: boolean, isIconComp?: boolean }>`
  height:${p => p.selected ? '63px' : '60px'};;
  width:${p => p.selected ? '48px' : '45px'};;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret && p.isIconComp ? '' : 'margin-left:1rem;'}
  border-color: ${theme.color.select};
  border-width: ${p => p.selected ? '4px' : 0};
  border-style: solid;
  ${p => isTotzeret && p.isIconComp ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const BlueRadioButton = styled(Radio) < { disabled?: boolean }> `
  *{
    color: ${p => !p.disabled ? theme.color.blue : theme.color.blue + 80};
  }
`
const BlueCheckbox = styled(Checkbox) <{ disabled?: boolean }>`
  *{
    color: ${p => !p.disabled ? theme.color.blue : theme.color.blue + 80};
  }
`
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const IconSelect = (
  { icons, onChange, givenSelectedIcon }:
    { icons: Description[], onChange: (v: Description) => void, givenSelectedIcon: string | undefined }) => {
  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(givenSelectedIcon)
  const clickHandler = (iconOptions: Description) => {
    setSelectedIcon(iconOptions.icon)
    onChange(iconOptions)
  }
  return (
    <IconsContainer>
      {
        icons.map((iconOptions: Description) => (
          <IconBg
            selected={selectedIcon == iconOptions.icon}
            color={iconOptions.iconColor}
            onClick={() => clickHandler(iconOptions)}>
            <Icon size={30} name={iconOptions.icon} />
          </IconBg>
        ))
      }
    </IconsContainer >
  )
}

const RadioComponent = ({ onChange, radioButtons, value, disabled = false }: { onChange: any, radioButtons: Array<string>, value: any, disabled?: boolean }) => (
  <FormControl>
    <RadioGroup
      onChange={onChange}
      value={value}>
      {radioButtons.map((text: string) =>
        <FormControlLabel
          style={{ marginBottom: 5 }}
          value={text}
          label={text}
          control={<BlueRadioButton disabled={disabled} />}
        />
      )}
    </RadioGroup>
  </FormControl>
)

const RatioSound = ({ text }) => {
  const [playing, setPlay] = useState(false)

  emergencySound.addEventListener("ended", () => {
    setPlay(false)
  });

  const onPlay = () => {
    emergencySound.play();
    setPlay(true)
  }

  const onStop = () => {
    emergencySound.pause();
    emergencySound.currentTime = 0;
    setPlay(false)
  }

  return (
    <>
      <span style={{width: '30px', padding: '0px', display: 'flex', cursor: 'pointer'}}>
        {text == 'Emergency' && !playing && <PlayCircleFilled onClick={onPlay}/>}
        {text == 'Emergency' && playing && <PauseCircleFilled onClick={onStop}/>}
      </span>
    </>
  )
}

const RadioComponent2 = ({ onChange, radioButtons, value, disabled = false }: { onChange: any, radioButtons: any, value: any, disabled?: boolean }) => (
  <FormControl>
    <RadioGroup
      onChange={onChange}
      value={value}>
      {Object.keys(radioButtons).map((text: string) =>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            style={{ marginBottom: 5 }}
            value={text}
            label={radioButtons[text]}
            control={ <BlueRadioButton disabled={disabled} />}
          />
          <RatioSound text={text} />
        </div>
      )}
    </RadioGroup>
  </FormControl>
)

const AcordionComponent = ({ children, headerText, secondText, Icon, disabled = false }: { headerText?: string, children: any, secondText?: string | null, Icon?: any, disabled?: boolean }) => {
  const [open, setopen] = useState(false)
  return (
    <AccordionContainer>
      <CustomAccordion
        disabled={disabled}
        expanded={open}
      >
        <AccordionSummary
          onClick={() => setopen(s => !s)}
          expandIcon={<ExpandMore />}>
          <Row>
            {Icon && <Icon />}
            {headerText && <Text
              weight={'normal'}
              size={'lg'}
              textcolor={theme.color.black}
            >{headerText}</Text>}

            {secondText && !open && <Text
              weight={'normal'}
              size={'sm'}
              textcolor={theme.color.black + 66}
            >{`${secondText}`}</Text>}
          </Row>
        </AccordionSummary>
        <AccordionDetails
          style={{ flexDirection: 'column', paddingRight: 30 }}
        >
          {children}
        </AccordionDetails>
      </CustomAccordion>
    </AccordionContainer>
  )
}

const AcordionComponent2 = ({ headerText, secondText, Icon, disabled = false }: { headerText?: string, children: any, secondText?: string | null, Icon?: any, disabled?: boolean }) => {
  return (
    <AccordionContainer>
      <CustomAccordion
        disabled={disabled}
        expanded={false}
      >
        <AccordionSummary>
          <Row>
            {headerText && <Text
              weight={'normal'}
              size={'lg'}
              textcolor={theme.color.black}
            >{headerText}</Text>}

            {secondText && <Text
              weight={'normal'}
              size={'sm'}
              textcolor={theme.color.black + 66}
            >{`${secondText}`}</Text>}
          </Row>
        </AccordionSummary>
        <AccordionDetails
          style={{ flexDirection: 'column', paddingRight: 30 }}
        >
        </AccordionDetails>
      </CustomAccordion>
    </AccordionContainer>
  )
}

const IconComp = () => {
  const { groups } = useStore()
  return (
    <IconComponent>
      <IconBg
        selected={false}
        isIconComp
        color={groups.currentEditGroup?.group.description.iconColor || theme.color.defaultThemeColorLightGray}
      >
        <Icon size={30} name={groups.currentEditGroup?.group.description.icon || 'default'} />
      </IconBg>
      {isTotzeret && <ImgArrow color={groups.currentEditGroup?.group.description.iconColor || theme.color.defaultThemeColorLightGray}/>}
    </IconComponent>
  )
}

const LabelTab = observer(() => {
  const { groups } = useStore()
  const { t } = useTranslation('groupDialog')
  const [defaultLabel, setDefaultLabel] = useState(null)

  useEffect(() => {
   if (groups?.currentEditGroup?.group?.groupLabels?.length) {
    setDefaultLabel(groups?.currentEditGroup?.group?.groupLabels[0])
    const selectedCouncil = groups.regionalCouncil.find(council => council.id == groups?.currentEditGroup?.group?.mcEntityID); 
    groups.setSelectedConcil(selectedCouncil)
   } 
    
  }, [])

  const getLabelTitle = (label) => {
    if(!groups.regionalCouncilSelected) {
      return ''
    }
     
    const groupsWithLabels = toJS(groups.currentGroups)
     .filter((g: Group) => g.mcEntityID == null || g.mcEntityID == groups.regionalCouncilSelected.id)
     .filter((g: Group) => g.groupLabels?.length > 0 && g.groupLabels?.includes(label))

     const group = groupsWithLabels.length > 0 ? groupsWithLabels[0] : null
     const currentGroup = groups?.currentEditGroup?.group
     const groupIds =  groupsWithLabels.map(g => g.id)

    if (groupIds.includes(currentGroup?.id) && (currentGroup?.mcEntityID == groups.regionalCouncilSelected.id)) {
      return label
    }

    return group != null ? label + ` - ${t`associatedTo`} ${group?.name}` : label
  }

  const isLabelDisabled = (label) => {
    if(!groups.regionalCouncilSelected) {
      return ''
    }

    const groupsWithLabels = toJS(groups.currentGroups)
     .filter((g: Group) => g.mcEntityID == null || g.mcEntityID == groups.regionalCouncilSelected.id)
     .filter((g: Group) => g.groupLabels?.length > 0 && g.groupLabels?.includes(label))

    const group = groupsWithLabels.length > 0 ? groupsWithLabels[0] : null
    const currentGroup = groups?.currentEditGroup?.group
    const groupIds =  groupsWithLabels.map(g => g.id)

    if (groupIds.includes(currentGroup?.id) && (currentGroup?.mcEntityID == groups.regionalCouncilSelected.id)) {
      return false
    }

    return group != null
  }

  const getLabelColor = (label) => {
    if(!groups.regionalCouncilSelected) {
      return ''
    }
    
    const groupsWithLabels = toJS(groups.currentGroups)
     .filter((g: Group) => g.mcEntityID == null || g.mcEntityID == groups.regionalCouncilSelected.id)
     .filter((g: Group) => g.groupLabels?.length > 0 && g.groupLabels?.includes(label))

     const group = groupsWithLabels.length > 0 ? groupsWithLabels[0] : null
     const currentGroup = groups?.currentEditGroup?.group
     const groupIds =  groupsWithLabels.map(g => g.id)

    if (groupIds.includes(groups?.currentEditGroup?.group?.id) && (currentGroup?.mcEntityID == groups.regionalCouncilSelected.id)) {
      return theme.color.blue
    }

    return group != null ? theme.color.blue + 66 : theme.color.blue 
  }
  
  return (
    <AcordionComponent
          headerText={t`associatedGroups`}
          secondText={(groups?.currentEditGroup?.group?.groupLabels?.length && !groups.associatedLabels) ?
            `${t('groupDialog:associatedGroup', { groupName: `"${groups?.currentEditGroup?.group?.groupLabels[0]}"`, concil: groups.getDefaultRegionalCouncil().name || '' })}` :
            (groups.associatedLabels === AssociatedGroupsLabel.remove && groups?.currentEditGroup?.group?.groupLabels?.length) ? `${t`associatedGroupsRemove`} ${groups?.currentEditGroup?.group?.groupLabels[0]} `
              : groups.associatedLabels === AssociatedGroupsLabel.remove ? t`noAssociated` :
                groups.associatedLabels ? `${t('groupDialog:associatedGroup', { groupName: `"${groups.associatedLabels}"`, concil: groups.getDefaultRegionalCouncil().name || '' })}`
                  : t`noAssociated`}
        >
          <div style={{ width: '50%' }}>
            <AutoCompleteContainer
              value={groups.regionalCouncilSelected}
              defaultValue={groups.getDefaultRegionalCouncil()}
              onChange={async (value: any, v: any) => {
                groups.setSelectedConcil(v)

                if(groups?.currentEditGroup?.group?.groupLabels?.length &&
                  (groups?.currentEditGroup?.group?.mcEntityID != null && 
                    groups?.currentEditGroup?.group?.mcEntityID == v.id
                  )) {
                   setDefaultLabel(groups?.currentEditGroup?.group?.groupLabels[0]) 
                } else {
                  setDefaultLabel(null)
                }
              }}
              disabled={groups.isDisableToselectCouncil()}
              options={groups.regionalCouncil}
              placeholder={t`selectConcil`}
              renderOption={(option: any) => option?.name}
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) =>
                <TextField {...params} value={groups.regionalCouncilSelected || ''} label={t`selectConcil`} variant="outlined" />}
            />
          </div>

          <FormControl>
            <RadioGroup
              value={defaultLabel}
              onChange={(event, n: AssociatedGroupsLabel) => {
                groups.setAssociatedLabel(event.target.value)
                setDefaultLabel(event.target.value)
              }}
              
            >
              {Array.isArray(groups.associatedDefaultLabels) && [...groups.associatedDefaultLabels, AssociatedGroupsLabel.remove].map((label: string) =>
                <FormControlLabel
                  style={{ marginBottom: 5 }}
                  value={label}
                  label={getLabelTitle(label)}
                  disabled={isLabelDisabled(label)}
                  control={<Radio style={{ color: getLabelColor(label) }}/>} 
                />
              )}
            </RadioGroup>
          </FormControl>
        </AcordionComponent>
  )
})

interface props {
  onClose: () => void
  createGroup?: boolean
}

const EditGroup: FC<props> = ({ createGroup = false, onClose }) => {
  const { t } = useTranslation('groupDialog')
  const { groups, uiStore, orgs: orgsStore, user: userStore } = useStore()
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [selectedProvider, setSelectedProvider] = useState(undefined)
  const currentEditGroupCategory: string = groups?.currentEditGroup?.group.description.category
  const currentEditGroupSubCategory: string = groups?.currentEditGroup?.group.description.subCategory
  const groupIsRegularGroup: boolean = groups.currentEditGroup?.group.duplex === 3 ? false : groups.currentEditGroup?.group.duplex === 0 || groups.currentEditGroup?.group.duplex === 2
  const groupIsServiceCallGroup: boolean = groups.currentEditGroup?.group.duplex === 3 ? false : groups.currentEditGroup?.group.duplex === 1 || groups.currentEditGroup?.group.duplex === 2
  const canSeeProviders = userIsDirectorOrSuperAdmin() && orgsStore.orgProvider && groupIsServiceCallGroup
  let groupTypeSecondText = `${groupIsRegularGroup ? t`messages` : ''} ${(groupIsRegularGroup && groupIsServiceCallGroup) ? '+' : ''} ${groupIsServiceCallGroup ? t`supportCall` : ''}`
  const isSendSms: boolean = groups.currentEditGroup?.group.sendSMS || false
  const isSendEmail: boolean = groups.currentEditGroup?.group.sendEmail || false

  const isUserAdmin = userStore.user?.role === Role.ADMIN;
  
  if (canSeeProviders) {
    let text = (selectedProvider ? (orgsStore.orgProvider?.providerId?.toString() === selectedProvider ? orgsStore.orgProvider?.providerName : '') : groups.currentEditGroup?.group.providerName) || ''
    if (text) {
      groupTypeSecondText += ` (${text})`
    }
  }
  else if (groups.currentEditGroup?.group.providerId && groups.currentEditGroup?.group.providerName) {
    groupTypeSecondText += ` (${(groups.currentEditGroup?.group.providerName)})`
  }
  const cats: Description[] = [...groupCategories.reduce((set, curr) => set.add(curr.category), new Set())]
  const subCats: Description[] = [...groupCategories.filter(groupCategory => groupCategory.subCategory !== '' && groupCategory.category === currentEditGroupCategory).reduce((set, curr) => set.add(curr.subCategory), new Set())]

  const groupsDescriptions: Description[] = groupCategories.filter(groupCategory =>
    groupCategory.category === currentEditGroupCategory &&
    (currentEditGroupSubCategory ? groupCategory.subCategory === currentEditGroupSubCategory : true))

  const showSubCats: boolean = !!cats?.length && Boolean(currentEditGroupCategory) && !!subCats?.length
  const showIcons: boolean = (!!subCats?.length && Boolean(currentEditGroupSubCategory)) || (Boolean(currentEditGroupCategory) && !subCats?.length)
  const selectedIcon: string | undefined = groupCategories.find(groupCategory => groupCategory.category === currentEditGroupCategory && groupCategory.subCategory === currentEditGroupSubCategory && groupCategory.iconName == groups.currentEditGroup?.group?.description.iconName)?.icon
  const handleProviderChange = (e: any) => {
    setSelectedProvider(e.target.value)
  }
  const handleRemove = () => {
    uiStore.openWarningModal({
      text: `${t`remove:deleteGroup`}?`,
      secondText: groups.currentEditGroup?.group?.name,
      okAction: () => {
        groups.removeGroup()
        groups.closeMembersModal()
      }
    })
  }

  const handleOk = async () => {
    setDisabledButton(true)
    if (groups.currentEditGroup) {
      const removeProvider = !groupIsServiceCallGroup && (groups.currentGroup?.group.duplex === 1 || groups.currentGroup?.group.duplex === 2)
      const res = await groups.createUpdateGroup()
      if (selectedProvider) {
        if (selectedProvider === 'none' || removeProvider) {
          groups.deleteGroupProvider()
        } else {
          const found = orgsStore.orgProvider
          if (found) {
            groups.setGroupProvider(found)
          }
        }
      }
    }
    setDisabledButton(false)
    onClose()
  }

  const disabledSend = () => {
    if (groups.currentEditGroup?.group?.name === '') {
      return true
    }
    if (groups.currentEditGroup?.group?.duplex === 3) {
      return true
    }
    if (disabledButton) {
      return true
    }
    return false
  }

  const renderSmsAndEmailValue = () => {
    const sendSMS = !!groups.currentEditGroup?.group?.sendSMS
    const sendEmail = !!groups.currentEditGroup?.group?.sendEmail

    if(sendSMS && sendEmail) {
      return t`send_sms_and_email_true`
    }

    if(sendSMS || sendEmail) {
      if(sendSMS) {
        return t`send_email_false`
      }
      if(sendEmail) {
        return t`send_sms_false`
      }
    } else {
      return t`send_sms_and_email_false`
    }
  }

  return (
    <>
      <Container >
        <StyledTextField
          value={groups.currentEditGroup?.group?.name}
          id="outlined-basic"
          label={t`groupName`}
          variant="outlined"
          onChange={(event) => groups.setCurrentEditGroupName(event.target.value)}
        />

        {userIsDirectorOrSuperAdmin() && <AcordionComponent
          headerText={t`soundTitle`}
          secondText={t('soundDescription', { value: groups?.currentEditGroup?.group.description?.sound ? t(`sound${groups?.currentEditGroup?.group.description?.sound}`) : t("soundApp")})}
        >
          <RadioComponent2
            onChange={(_: any, v: string) => {
              if (groups.currentEditGroup?.group) {
                groups.setCurrentEditGroupDescription({ ...groups?.currentEditGroup?.group.description, sound: v });
              }
            }}

            value={groups?.currentEditGroup?.group.description?.sound ?? 'App'}
            radioButtons={{ "App": t`soundApp`, "Emergency": t`soundEmergency` }}
          />
        </AcordionComponent>}

        {!userIsDirectorOrSuperAdmin() && 
        userIsOrganizer() && 
        groups?.currentEditGroup?.group.description.sound == 'Emergency' &&
        <AcordionComponent2
          headerText={t`soundTitle`}
          secondText={t('soundDescription', { value: groups?.currentEditGroup?.group.description?.sound ? t(`sound${groups?.currentEditGroup?.group.description?.sound}`) : t("soundApp")})}
        />}

        {userCanSeeAssociatedGroups() && groupIsRegularGroup && <LabelTab />}
        <AcordionComponent
          headerText={t`groupType`}
          secondText={groupTypeSecondText}
        >
          <FormControlLabel
            onChange={(_: any, v: boolean) => groups.setCurrentEditGroupDuplex(v, groupIsServiceCallGroup)}
            checked={groupIsRegularGroup}
            value={groupIsRegularGroup}
            label={t`regularGroup`}
            disabled={createGroup ? false : ((groupIsRegularGroup && !groupIsServiceCallGroup) ? true : false)}
            control={<BlueCheckbox />}
          />
          <FormControlLabel
            onChange={(_: any, v: boolean) => groups.setCurrentEditGroupDuplex(groupIsRegularGroup, v)}
            checked={groupIsServiceCallGroup}
            value={groupIsServiceCallGroup}
            label={t`serviceCallGroup`}
            control={<BlueCheckbox />}
            disabled={groupIsServiceCallGroup && !groupIsRegularGroup}
          />
          {canSeeProviders && (
            <>
              <Space height={1} />
              <Text style={{ margin: 0 }}
                weight={'normal'}
                size={'lg'}
                textcolor={theme.color.black}
              >{t`associateGroupToSystem`}</Text>
              <Space height={0.5} />
              <FormControl component="fieldset">
                <RadioGroup aria-label="system" name="system" value={selectedProvider || groups.currentEditGroup?.group.providerId?.toString() || 'none'} onChange={handleProviderChange}>
                  <FormControlLabel style={{ marginBottom: 5 }} value={'none'} control={<Radio style={{ color: theme.color.blue }} />} label={t`noAssociated`} />
                  {orgsStore.orgProvider && <FormControlLabel style={{ marginBottom: 5 }} value={Number(orgsStore.orgProvider.providerId).toString()} control={<Radio style={{ color: theme.color.blue }} />} label={orgsStore.orgProvider.providerName} />}
                </RadioGroup>
              </FormControl>
            </>
          )}
        </AcordionComponent>

        <AcordionComponent
          headerText={t`group_members`}
          secondText={groups.currentEditGroup?.group.group_is_member ? t`publicGroup` : t`privateGroup`}
        >
          <RadioComponent
            onChange={(_: any, v: string) => groups.setCurrentEditGroupIsMember(t`publicGroup` == v)}
            value={groups.currentEditGroup?.group.group_is_member ? t`publicGroup` : t`privateGroup`}
            radioButtons={[t`publicGroup`, t`privateGroup`]}
          />
        </AcordionComponent>
        <AcordionComponent
          headerText={t`send_sms_and_email`}
          secondText={renderSmsAndEmailValue()}
        >
          <FormControlLabel
            onChange={() => groups.setCurrentEditIsSendingSmsOrEmail({isSendSms: !isSendSms, isSendEmail})}
            checked={isSendSms}
            label={'האם לשלוח הודעות סמס למשתמשים ללא האפליקציה'}
            control={<BlueCheckbox />}
          />
          <FormControlLabel
            onChange={() => groups.setCurrentEditIsSendingSmsOrEmail({isSendEmail: !isSendEmail, isSendSms})}
            checked={isSendEmail}
            label={'האם לשלוח הודעות למייל של משתמשים ללא האפליקציה'}
            control={<BlueCheckbox />}
          />
        </AcordionComponent>
        {(!groups.currentEditGroup?.group?.group_is_member && createGroup) && <AcordionComponent
          headerText={t`importMembers`}
          secondText={groups.importUsersUserGroup?.group?.name || ''}
        >
          <AutoCompleteContainer
            value={groups.importUsersUserGroup}
            onChange={(_: any, v: any) => groups.setImportUserGroup(v)}
            options={groups.currentUserGroups.filter((ug: UserGroup) => (ug.group.group_is_member === false && ug.group.members > 0))}
            placeholder={t`importMembers`}
            renderOption={(option: any) => option?.group?.name}
            getOptionLabel={(option: any) => option?.group?.name}
            renderInput={(params) =>
              <TextField {...params} value={groups.importUsersUserGroup || ''} label={t`importMembers`} variant="outlined" />}
          />
        </AcordionComponent>}
        <AcordionComponent
          headerText={t`defaultCanSendMessage`}
          secondText={groups.currentEditGroup?.group.group_is_sender ? t`defaultCanSendMessageTrue` : t`defaultCanSendMessageFalse`}
        >
          <RadioComponent
            onChange={(_: any, v: string) => groups.setCurrentEditGroupCanSend(t`defaultCanSendMessageTrue` == v)}
            value={groups.currentEditGroup?.group.group_is_sender ? t`defaultCanSendMessageTrue` : t`defaultCanSendMessageFalse`}
            radioButtons={[t`defaultCanSendMessageFalse`, t`defaultCanSendMessageTrue`]}
          />
        </AcordionComponent>
        {orgsStore.currentCommunity.allowShare && <AcordionComponent
          headerText={t`defaultCanShareMessage`}
          secondText={groups.currentEditGroup?.group.allowShare ? t`defaultCanShareMessageTrue` : t`defaultCanShareMessageFalse`}
        >
          <RadioComponent
            onChange={(_: any, v: string) => {
              groups.setCurrentEditIsShare(t`defaultCanShareMessageTrue` == v)
            }}
            value={groups.currentEditGroup?.group.allowShare ? t`defaultCanShareMessageTrue` : t`defaultCanShareMessageFalse`}
            radioButtons={[t`defaultCanShareMessageFalse`, t`defaultCanShareMessageTrue`]}
          />
        </AcordionComponent>}
        {isUserAdmin && <AcordionComponent
          headerText={t`joinMethod`}
          secondText={groups?.currentEditGroup?.group.group_is_favourite ? t`defualtAppear` : t`defualtNotAppear`}
        >
          <RadioComponent
            onChange={(_: any, v: string) => groups.setCurrentEditGroupIsFavourite(t`defualtAppear` == v)}
            value={groups?.currentEditGroup?.group.group_is_favourite ? t`defualtAppear` : t`defualtNotAppear`}
            radioButtons={[t`defualtAppear`, t`defualtNotAppear`]}
          />
        </AcordionComponent>}

        <AcordionComponent
          headerText={t`notifcationsAlert`}
          secondText={groups.currentEditGroup?.group.group_is_subscriber ? t`notifcationsOn` : t`notifcationsOff`}
        >
          <RadioComponent
            onChange={(_: any, v: boolean) => groups.setCurrentEditGroupIsSubscriber(t`notifcationsOn` == v)}
            value={groups.currentEditGroup?.group.group_is_subscriber ? t`notifcationsOn` : t`notifcationsOff`}
            radioButtons={[t`notifcationsOn`, t`notifcationsOff`]}
          />
        </AcordionComponent>
        <AcordionComponent
          headerText={groups.currentEditGroup?.group.description ? `${currentEditGroupCategory ? currentEditGroupCategory : ''} ${currentEditGroupSubCategory ? ',' + currentEditGroupSubCategory : ''}` : t`icon`}
          Icon={IconComp}
        >
          <AutoCompleteContainer
            value={currentEditGroupCategory || ''}
            onChange={(_: any, v: any) => groups.setCurrentEditGroupDescription({ ...groups?.currentEditGroup?.group.description, category: v, subCategory: '' })}
            id="combo-box-cat"
            options={cats}
            placeholder={t`type`}
            getOptionSelected={option => option}
            renderOption={option => <div>{option}</div>}
            renderInput={(params) =>
              <TextField
                {...params}
                value={currentEditGroupCategory || ''}
                label={t`category`}
                variant="outlined"
              />}
          />
          {showSubCats && <AutoCompleteContainer
            value={currentEditGroupSubCategory || ''}
            onChange={(_: any, v: any) => groups.setCurrentEditGroupDescription({ ...groups?.currentEditGroup?.group.description, subCategory: v })}
            id="combo-box-subCats"
            options={subCats}
            placeholder={t`type`}
            getOptionSelected={option => option}
            renderOption={option => <div>{option}</div>}
            renderInput={(params) =>
              <TextField
                {...params}
                value={currentEditGroupSubCategory || ''}
                label={t`subCategory`}
                variant="outlined"
              />}
          />}
          {showIcons && <IconSelect
            icons={groupsDescriptions}
            onChange={(v: any) => groups.setCurrentEditGroupDescription({ ...groups?.currentEditGroup?.group.description, ...v })}
            givenSelectedIcon={selectedIcon}
          />}
        </AcordionComponent>
      </Container>
      <div style={{ flex: 1 }} />
      <ConfirmButtonsContainer>
        <Overlay color={groups.currentGroup?.group?.description?.color + 33 || theme.color.defaultThemeColorLightGray + 33}>
          {(!createGroup && canDeleteGroup()) ? <ConfirmButton
            hoverColor={theme.color.dangerRed}
            bgColor={theme.color.red}
            onClick={handleRemove}>{t`clearGroup`}
          </ConfirmButton> : <div />}
          <ConfirmButton
            disabled={disabledSend()}
            bgColor={(disabledSend()) ? theme.color.confirmButton + 33 : theme.color.confirmButton}
            onClick={handleOk}>{t`save`}
          </ConfirmButton>
        </Overlay>
      </ConfirmButtonsContainer>
    </>
  )
}

export default observer(EditGroup)
